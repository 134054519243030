import React from 'react';
import {Layout} from 'components/pageLayouts';
import {SketchContainer} from 'components/molecules';
import perlin from 'sketches/perlin';
import sineline from 'sketches/sineline';
import styles from './styles.module.scss';

const Canvas = () => {
	return (
		<Layout>
			<SketchContainer title={'perlin'} sketch={perlin} />
			<SketchContainer title={'sineline'} sketch={sineline} />
		</Layout>
	);
};

export default Canvas;
