const sketch = function(p) {
	let c = 10;
	let q = 0;

	p.setup = function() {
		p.createCanvas(400, 400);
		p.angleMode(p.DEGREES);
		p.background(255);
	};

	p.draw = function() {
		p.background(255);

		p.translate(p.width / 2, p.height / 4);

		p.rotate((q += 0.5));

		for (let n = 0; n < 1000; n++) {
			let angle = n * 137.5;
			let magnitude = c * p.sqrt(n);

			let circleRadius = 8;
			let x = magnitude * p.cos(angle);
			let y = magnitude * p.sin(angle);

			p.fill(255, 215, 0);
			p.stroke(255);
			p.circle(x, y, circleRadius);
		}
	};
};

export default sketch;
