const sketch = function(p) {
	let c = 10;
	let q = 0;
	let total = 1;
	const MAX = 500;
	let change = 1;

	p.setup = function() {
		p.createCanvas(400, 400);
		p.angleMode(p.DEGREES);
		p.background(255);
	};

	p.draw = function() {
		p.background(255);

		p.translate(p.width / 2, p.height / 2);

		p.rotate((q -= 0.1));

		for (let n = 0; n < total; n++) {
			let angle = n * 137.5;
			let magnitude = c * p.sqrt(n);

			let circleRadius = 8;
			let x = magnitude * p.cos(angle);
			let y = magnitude * p.sin(angle);

			p.fill(255, 215, (magnitude * 1.3) % 255);
			p.stroke(255);
			p.circle(x, y, circleRadius);
		}

		if (total === MAX) {
			change = -change;
		} else if (total < 1) {
			change = -change;
		}

		total += change;
	};
};

export default sketch;
